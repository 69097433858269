.main-wrapper {
    background: var(--secondary_light_bg_E9F5FB);
}

#cx-main {
    padding: 120px 20px 20px 20px;
    margin-left: 270px;
    height: 100vh;
    overflow-y: auto;

}

.capital {
    text-transform: capitalize;
}

.tittleHead {
    padding: 10px;
}

.tittleModal {
    color: #21c896 !important;
}

.formBox {
    padding: 10px 20px;

    .formBody {
        margin-bottom: 15px;
    }

    .form-select {
        &:focus {
            box-shadow: none;
        }
    }

    .form-control {
        &:focus {
            box-shadow: none;
        }
    }
}

.buttonBox {
    text-align: end;

    .submitBtn {
        border: none;
        background-color: #21c896;
        color: #fff;
        height: 40px;
        width: 140px;
        border-radius: 12px;
    }
}