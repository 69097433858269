.INventorySingle {
    background-color: white;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 20px;
    position: relative;

    .rating {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 1;
        font-size: 12px;
        color: #A3AED0;
    }

    .InventoryImage {
        text-align: center;

        img {
            height: 120px;
            width: 100%;
            object-fit: contain;
        }
    }

    .ProductDetails {
        display: flex;
        justify-content: space-between;

        .productName {
            .name {
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: -0.02em;
                text-align: left;
                color: var(--primary_dark_bg_21C896);
                margin-bottom: 5px;
            }

            .brand {
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: -0.02em;
                text-align: left;
                color: var(--primary_dark_bg_21C896);
                margin-bottom: 5px;
            }
        }

        .checkSwitch {
            .form-check-input {
                background-color: #C1CFE0;
                border-color: #C1CFE0;
            }

            .form-check-input:checked {
                background-image: url("../images/pet-shop-img/notcheck_input_bg_img.svg");
                background-color: var(--primary_dark_bg_21C896);
                border-color: var(--primary_dark_bg_21C896);
            }

            .form-check-input:focus {
                border-color: transparent;
                outline: 0;
                box-shadow: none;
            }

            .form-check-input {
                background-image: url("../images/pet-shop-img/check_input_bg_img.svg");
                background-size: contain;
                // border: 1px solid var(--primary_dark_bg_21C896);
            }
        }
    }

    .discribtion {
        font-size: 12px;
        font-weight: 500;
        color: #A3AED0;
        margin-bottom: 15px;
        height: 40px;
        overflow-y: auto;
    }

    .prising {
        display: flex;
        justify-content: space-evenly;
        text-align: center;
        margin-bottom: 15px;

        .QuantityDetails {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -0.02em;
            color: var(--primary_dark_bg_21C896);
            margin-bottom: 5px;

            .QuantityCount {
                margin-bottom: 0;
            }

        }

        .line {
            border: 1px solid #A3AED0;
            margin: 4px 0;
        }

        .PriceDetails {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -0.02em;
            color: var(--primary_dark_bg_21C896);
            margin-bottom: 5px;

            .PriceCount {
                margin-bottom: 0;
            }
        }

        .LightColor {
            color: #A3AED0;
            margin-bottom: 1px;

        }
    }

}