.google-autocomlete-input {
  border: none;
  border-radius: 0px !important;
  border-bottom: 1px solid var(--lightGreyColor999999);
  padding-left: 26px;
  color: var(--darkBackgroundcolor1D7C7C);
  font-size: 16px;

  &:focus {
    box-shadow: none !important;
    border-bottom: 2px solid var(--darkBlackColor000);
  }

  &::placeholder {
    color: var(--darkBackgroundcolor1D7C7C) !important;
    font-size: 16px;
  }
}
