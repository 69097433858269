.OrderInner {
    height: calc(100vh - 140px);
    overflow-y: scroll;

    .OrederInnerHead {
        display: flex;
        align-items: center;

        img {
            cursor: pointer;
            margin-right: 15px;
        }

        .resultOrder {
            font-size: 16px;
            font-weight: 400;
            color: #A3AED0;
            margin-bottom: 0;

            span {
                font-size: 16px;
                font-weight: 500;
                color: #042486;
            }
        }
    }

    .orderInnerTabSection {
        background-color: white;
        padding: 20px;
        border-radius: 15px;

        .innerContain {
            .borderLeft {
                border-right: 1px solid #E9F5FB;
                padding-right: 0;
            }

            .status_table_wrapper {
                margin-top: 20px;

                .table-wrapper {
                    padding: 0;

                    thead {
                        tr {
                            background-color: #ECF9F5;
                            border: none;

                            th {
                                font-size: 16px;
                                font-weight: 500;
                                color: #A3AED0;

                                &:first-child {
                                    border-radius: 10px 0 0 10px;
                                }

                                &:last-child {
                                    border-radius: 0 10px 10px 0;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                font-size: 16px;
                                font-weight: 500;
                                color: #042486;
                            }
                        }
                    }
                }
            }

            .form-select {
                width: 160px;
                margin-right: 20px;
                border-radius: 25px;
                color: var(--primary_dark_bg_21C896);
            }

            .cx-btn-dark {
                box-shadow: none;
            }

            table {
                width: 100%;
                height: 100%;

                .labelName {
                    padding-bottom: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #A3AED0;
                    margin-right: 20px;
                }

                .ContainName {
                    padding-bottom: 20px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: -0.02em;
                    text-align: left;
                    color: var(--primary_dark_bg_21C896);
                }
            }
        }

        .review_ratings_wrapper {
            .review_title_wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;

                .review_title {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    color: var(--primary_dark_bg_21C896);
                    margin-bottom: 0;
                }

                .rating_star_wrapper {
                    img {
                        margin-right: 5px;
                    }

                    span {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 24px;
                        color: var(--primary_dark_bg_21C896);
                    }
                }
            }

            .review_description {

                .review_text {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: left;
                    margin-bottom: 0;
                    color: #A3AED0;
                }

                .review_img_wrapper {
                    margin-top: 20px;
                    display: flex;

                    img {
                        border-radius: 10px;
                        width: 60px;
                        height: 60px;
                        margin-right: 10px;
                    }
                }
            }
        }

        .no_reviews {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 70px;

            img {}

            .no_review_text {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #A3AED0;
            }
        }
    }

    .prescription_wrapper {
        margin-top: 20px;


        .pw_title {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-align: left;
            color: var(--primary_dark_bg_21C896);
            margin-bottom: 5px;
        }

        .pw_card {
            cursor: pointer;
            border-radius: 20px;
            background-color: #fff;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 244px;

            .pwc_text {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.02em;
                text-align: left;
                color: var(--primary_dark_bg_21C896);
                margin-bottom: 0;
                margin-top: 10px;
            }
        }

    }

    .product_summary_wrapper {
        margin-top: 20px;

        table {
            thead {
                tr {
                    background-color: #ECF9F5;


                    th {
                        &:first-child {
                            border-radius: 10px 0 0 10px;
                        }

                        &:last-child {
                            border-radius: 0 10px 10px 0;
                        }
                    }
                }
            }

            tbody {

                tr {
                    td {
                        color: var(--primary_dark_bg_21C896);
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: -0.02em;
                        text-align: left;


                        label.photo {
                            color: var(--primary_dark_bg_21C896);

                        }
                    }
                }
            }
        }
    }

    .download_btn{
        padding: 0 0 0 20px;
        img {
            margin-right: 5px;
        }
        button {
            border-radius: 7px;
            padding: 5px 10px 5px 10px;
            background-color: transparent;
            cursor: pointer;
            color: #05CD99;
            // border: none;
            border: 1px solid #05CD99;
        }
    }

    .order_status_wrapper_bottom {
        padding: 13px 20px;
        border-radius: 10px;
        width: 100%;
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-align: left;
            margin-bottom: 0;
        }
    }

    .order_progress {
        background-color: #FFCE2040;

        p {
            color: #E1B103;
        }
    }

    .order_cancelled {
        background-color: #EE5D5040;

        p {
            color: #EE5D50;
        }
    }

    .order_complete {
        background-color: #05CD9940;

        p {
            color: #05CD99;
        }
    }

    .photograph_wrapper {
        border-radius: 20px;
        padding: 10px;
        background-color: #fff;
        margin-bottom: 20px;

        .photograph_wrapper_inner {
            margin-top: 10px;

            img {
                height: 200px;
                width: 200px;
                border-radius: 20px;
                object-fit: cover;
            }
        }

        .right_photos_list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;

            img {
                margin-bottom: 15px;
                width: 60px;
                height: 60px;
                border-radius: 20px;
                cursor: pointer;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    .right {
        border-radius: 20px;
        background-color: var(--bg_White);
        padding: 20px;

        .visits-tab-wrapper {
            .today-heading {
                display: flex;
                align-items: center;

                .line-style {
                    width: 34%;
                }

                .line {
                    height: 1px;
                    background-color: var(--secondary_light_bg_E9F5FB);
                    width: 100%;
                }
            }

            .mainBoxy {
                margin: 10px 0 20px 0;
                border-radius: 10px;
                background-color: #ECF9F5;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .boxyFormat {
                width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    border-bottom: 2px solid #d7dcdf;
                    padding-bottom: 10px;
    
                }

            }

            .app-time-date {
                margin: 10px 0 20px 0;
                border-radius: 10px;
                background-color: #ECF9F5;
                padding: 10px;
                display: flex;
                // flex-direction: column;
                justify-content: space-between;
                align-items: center;


                // .left-app-time-date{
                // }
            }
        }

        .invoices-tab-wrapper {
            .table-wrapper {
                overflow-x: auto;
                padding: 0;

                table {
                    width: 350px;

                    td,
                    th {
                        padding: 5px;
                    }
                }
            }
        }
    }
}