.appointment-wrapper {
  .dark-btns-wrapper {
    margin-bottom: 20px;
    text-align: right;

    .dark-btns {
      border: none;
      background-color: var(--text_dark_blue_34528A);
      color: var(--secondary_light_bg_E9F5FB);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.28px;
      height: 40px;
      padding: 0 20px;
      border-radius: 10px;
    }
  }

  .reportsTabs {
    .nav-item {
      margin-right: 15px;
    }

    .nav-link.active {
      border-radius: 0;
      background: none;
      padding-bottom: 5px;
      position: relative;
      font-weight: 600;
      color: #21c896;

      &::after {
        border-bottom: 3px solid #21c896;
        bottom: -1.5px;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }

    .nav-link {
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0em;
      color: var(--text_light_table_heading_A3AED0);
    }

    .tob_nav_pills.nav.nav-pills {
      border-bottom: 1px solid #a3aed0;
      margin-bottom: 20px;
    }
  }

  .all-appoimt-wrapper {
    height: calc(100vh - 253px);
    overflow-y: auto;
    overflow-x: hidden;

    .right {
      .top-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--secondary_light_bg_E9F5FB);

        .left-heading {
          label {
            color: var(--text_dark_blue_34528A);
            font-size: 16px;
            font-weight: 500;
          }
        }

        .right-date {
          label {
            color: var(--text_light_table_heading_A3AED0);
            font-size: 12px;
            font-weight: 500;

            span {
              margin-left: 5px;
            }
          }
        }
      }

      .check-up-card {
        margin-top: 10px;
        padding: 10px;
        border-radius: 20px;
        border: 1px solid var(--secondary_light_bg_E9F5FB);
        background: var(--bg_White);
        box-shadow: 0px 2px 1.5px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: space-between;

        .left-check-up {
          display: flex;
          align-items: center;

          .left-img {
            margin-right: 10px;

            img {
              height: 60px;
              width: 60px;
              border-radius: 10px;
            }
          }

          .right-check-up-details {
            h5 {
              color: var(--text_dark_blue_34528A);
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 0;
            }

            label {
              color: var(----text_gray_646D82);
              font-size: 10px;
              font-weight: 400;
            }

            p {
              color: var(--text_dark_blue_34528A);
              font-size: 10px;
              font-weight: 400;
              margin-bottom: 0;
              margin-top: 5px;

              img {
                margin-right: 5px;
              }
            }
          }
        }

        .right-check-up {
        }
      }

      border-radius: 20px;
      padding: 10px;
      background-color: var(--bg_White);
    }
  }

  .search-filter-wrapper {
    display: flex;
    justify-content: space-between;

    .search-filter-left {
      // display: flex;
      width: 100%;

      .fltr-btn {
        border: none;
        background-color: var(--bg_White);
        border-radius: 10px;
        padding: 0 10px;
        height: 40px;
      }
    }

    .search-filter-right {
      width: 30%;
    }
  }

  .app-result-arrow-top {
    img {
      margin-right: 10px;
      cursor: pointer;
    }

    label {
      color: var(--text_light_table_heading_A3AED0);
      font-size: 16px;
      font-weight: 400;

      span {
        margin-left: 5px;
        color: var(--primary_dark_bg_21C896);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .app-details-wrapper {
    height: calc(100vh - 238px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;

    .left {
      border-radius: 20px;
      background-color: var(--bg_White);
      padding: 20px;

      .aap-heading {
        border-bottom: 1px solid var(--text_light_F6F6F6);
        margin-bottom: 20px;

        label {
          padding-bottom: 10px;
          color: var(--text_dark_blue_042486);
          font-size: 16px;
          font-weight: 500;
        }
      }

      .key {
        color: var(--text_light_table_heading_A3AED0);
        font-size: 14px;
        font-weight: 400;
      }

      .value {
        color: var(--text_dark_blue_34528A);
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      .status {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #21c896;
      }

      .status_red {
        color: #ee5d50;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: left;
      }

      .photo-video-wrapper {
        margin-top: 10px;
        padding-bottom: 30px;
        border-bottom: 1px solid var(--text_light_F6F6F6);

        .photo {
          height: 100px;
          width: 100%;
          border-radius: 20px;
        }

        .photo-ic_video {
          position: relative;

          .ic-video {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            height: 40px;
            width: 40px;
          }
        }

        .start-wrapper {
          .digno-type-select {
            margin: 20px 0;

            .form-select {
              color: var(--text_dark_blue_34528A);
              font-size: 12px;
              font-weight: 400;
              background-size: 20px;
              background-image: url(../images/ic_dropdown.svg);
            }

            .form-select:focus {
              box-shadow: none;
            }

            select {
              height: 40px;
              margin-top: 5px;
              border-radius: 10px;
              border: 1px solid var(--text_light_table_heading_A3AED0);
            }
          }

          .table-start-wrapper {
            .inner-tb {
              overflow-x: auto;
            }

            table {
              margin-top: 6px;
              width: 100%;
              border-radius: 20px;
              min-width: 700px;

              tr:first-child th:first-child {
                border-radius: 20px 0 0 0;
              }

              tr:first-child th:last-child {
                border-radius: 0 20px 0 0;
              }

              tr:last-child td:first-child {
                border-radius: 0 0 0 20px;
              }

              tr:last-child td:last-child {
                border-radius: 0 0 20px 0;
              }

              tr:last-child td {
                border-bottom: 1px solid var(--secondary_light_bg_E9F5FB);
              }

              tr:last-child {
                td:first-child {
                }

                td:last-child {
                }
              }

              tr {
                td:first-child {
                  border-left: 1px solid var(--secondary_light_bg_E9F5FB);
                }

                td:last-child {
                  border-right: 1px solid var(--secondary_light_bg_E9F5FB);
                }
              }

              thead {
                tr:first-child {
                  background-color: #f2f5ff;
                }

                th {
                  color: var(--text_light_table_heading_A3AED0);
                  font-size: 12px;
                  font-weight: 400;
                  padding: 10px;
                }
              }

              tbody {
                tr:last-child {
                }

                td {
                  color: var(--text_dark_blue_34528A);
                  font-size: 12px;
                  font-weight: 400;
                  padding: 10px;
                }
              }
            }

            .css-1u9des2-indicatorSeparator {
              display: none;
            }

            .css-u2piby-control:hover {
              border-color: unset;
            }

            .css-u2piby-control {
              box-shadow: none;
              height: 40px;
            }

            .css-1jqq78o-placeholder {
              color: var(--text_dark_blue_34528A);
            }

            .css-tj5bde-Svg {
              fill: #819fd8;
            }
          }
        }
      }

      .btn-wrapper {
        text-align: right;
        margin-top: 20px;

        .btn-yellow-filled {
          margin: 0 10px;
        }
      }
    }

    .right {
      border-radius: 20px;
      background-color: var(--bg_White);
      padding: 20px;

      .visits-tab-wrapper {
        .today-heading {
          display: flex;
          align-items: center;

          .line-style {
            width: 30%;
          }

          .line {
            height: 1px;
            background-color: var(--secondary_light_bg_E9F5FB);
            width: 100%;
          }
        }

        .app-time-date {
          margin: 10px 0 20px 0;
          border-radius: 10px;
          background-color: var(--secondary_light_bg_E9F5FB);
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .invoices-tab-wrapper {
        .table-wrapper {
          overflow-x: auto;

          table {
            width: 350px;

            td,
            th {
              padding: 5px;
            }
          }
        }
      }
    }

    .message-payment {
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px 20px;
      background: rgba(255, 206, 32, 0.25);

      label {
        margin-left: 10px;
        color: #e1b103;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.serach-input {
  background: url(../images/ic_search.svg) no-repeat scroll 10px 14px;
  padding-left: 30px;
  border-radius: 10px;
  background-color: var(--bg_White);
  border: none;
  height: 40px;
  // width: 70%;
  /* Card */
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);

  &::placeholder {
    color: var(--text_gray_8F9BBA);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;
  }

  &:focus {
    box-shadow: none;
  }
}

.serach-input1 {
  background: url(../images/ic_search.svg) no-repeat scroll 10px 14px;
  padding-left: 30px;
  border-radius: 10px;
  background-color: var(--bg_White);
  border: none;
  height: 40px;
  /* Card */
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);

  &::placeholder {
    color: var(--text_gray_8F9BBA);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;
  }

  &:focus {
    box-shadow: none;
  }
}

.scroll-table {
  height: calc(100vh - 273px);
  overflow-y: auto;

  table {
    min-width: 935px;
  }
}

.RemportTAbele {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.table-wrapper {
  background-color: var(--bg_White);
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 20px;

  .map-link {
    text-decoration: none;
    color: #34528a;
  }

  .tableImg {
    margin-right: 5px;
  }

  table {
    width: 100%;

    .tableBody {
      .status_complete {
        color: #21c896;
      }

      .status_cancelled {
        color: #ee5d50;
      }

      .status_pending {
        color: #adadad;
      }

      .status_progress {
        color: #ffce20;
      }
    }

    .pending-txt {
      color: var(--text_gray_ADADAD);
      font-size: 12px;
      font-weight: 500;
    }

    .view-details-btn {
      background: transparent;
      border: none;
      font-weight: 400;

      padding: 10px;
      font-size: 12px;
      color: var(--text_dark_blue_34528A);
    }

    .status-ic {
      margin-right: 5px;
    }

    .photo {
      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
    }

    .video-ic {
      position: relative;

      .ic-play {
        position: absolute;
        left: 0;
      }
    }

    .center {
      text-align: center;
    }
  }

  tbody {
    tr td {
      font-weight: 400 !important;

      padding: 10px;
      font-weight: bold;
      font-size: 12px;
      color: var(--text_dark_blue_34528A);
    }
  }

  thead {
    tr {
      border-bottom: 1px solid var(--secondary_light_bg_E9F5FB);
    }

    tr th {
      padding: 10px;
      color: var(--text_light_table_heading_A3AED0);
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.btn-blue-blank {
  border-radius: 32px;
  border: 1px solid var(--text_dark_blue_34528A);
  background: transparent;
  height: 40px;
  width: 140px;

  color: var(--text_dark_blue_34528A);
  font-size: 14px;
  font-weight: 500;
}

.btn-yellow-filled {
  border-radius: 32px;
  border: none;
  background: var(--bg_yellow);
  height: 40px;
  width: 140px;

  color: var(--secondary_light_bg_E9F5FB);
  font-size: 14px;
  font-weight: 500;
}

.btn-blue-filled {
  border-radius: 32px;
  border: none;
  background: var(--text_dark_blue_34528A);
  height: 40px;
  width: 140px;

  color: var(--secondary_light_bg_E9F5FB);
  font-size: 14px;
  font-weight: 500;
}

.key {
  color: var(--text_light_table_heading_A3AED0);
  font-size: 12px;
  font-weight: 400;
}

.value {
  color: var(--primary_dark_bg_21C896);
  font-size: 14px;
  font-weight: 500;
}

.status {
  font-size: 14px;
  font-weight: 500;
  color: #21c896;
}

.status_red {
  color: #ee5d50;
  font-size: 14px;
  font-weight: 500;
}

.requests-wrapper {
  .reject-btn {
    height: 36px;
    width: 110px;
    border: 1px solid var(--text_dark_blue_34528A);
    border-radius: 20px;
    background-color: transparent;
    margin-right: 10px;
    color: var(--text_dark_blue_34528A);
    font-size: 14px;
    font-weight: 500;
  }

  .accept-btn {
    height: 36px;
    width: 110px;
    border: none;
    border-radius: 20px;
    background-color: var(--text_dark_blue_34528A);
    color: var(--bg_White);
    font-size: 14px;
    font-weight: 500;
  }
}

.pagination-wrapper {
  label {
    color: var(--text_light_table_heading_A3AED0);
    font-size: 12px;
    font-weight: 400;
  }
}

.prescription-btn-wrapper {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .prescription-btn {
    .date {
      color: #2b3674;
      font-size: 14px;
      font-weight: 500;
    }

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #f2f5ff;
    padding: 30px 0;

    .ic_wrapper {
      align-items: center;
      justify-content: center;
      display: flex;
      height: 55px;
      border-radius: 50%;
      background-color: var(--text_dark_blue_34528A);
      width: 55px;
    }
  }
}

.main-vd-sec {
  overflow-x: hidden;
  height: calc(100vh - 193px);
  overflow-y: auto;
}

.video-call-wrapper {
  .right-video {
    padding: 20px;
    border-radius: 20px;
    background-color: var(--bg_White);
  }

  .left-video {
    padding: 20px;
    border-radius: 20px;
    background-color: var(--bg_White);
  }
}

.CustomerDetails-wrapper {
  .table-wrapper.left-table {
    height: calc(100vh - 200px);
    overflow-x: auto;
    margin-bottom: 0;

    table {
      min-width: 900px;
    }
  }

  .rating-wrapper {
    margin-top: 20px;

    .rating-card {
      margin-top: 5px;
      background-color: var(--secondary_light_bg_E9F5FB);
      padding: 10px;
      border-radius: 10px;

      .header-section {
        display: flex;
        justify-content: space-between;

        .left-rating {
          p {
            margin-bottom: 0 !important;
          }

          label {
            color: var(--text_light_table_heading_A3AED0);
            font-size: 12px;
            font-weight: 500;
          }
        }

        .right-rating {
          span {
            margin-left: 5px;
            color: var(--text_dark_blue_042486);
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      .paragraph {
        margin-top: 10px;

        p {
          color: var(--text_dark_blue_34528A);
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }
    }
  }

  .app-time-date.active {
    background-color: var(--text_dark_blue_34528A) !important;

    .value {
      color: var(--secondary_light_bg_E9F5FB) !important;
    }

    .time {
      color: var(--secondary_light_bg_E9F5FB);
      font-size: 12px;
      font-weight: 400;
    }
  }

  .app-time-date {
    .value {
    }

    .time {
      font-size: 12px;
      font-weight: 400;
      color: var(--text_dark_blue_34528A);
    }
  }

  .view-details-btn {
  }

  .top-btn-heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--text_light_F6F6F6);
    margin-bottom: 20px;
    padding-bottom: 10px;

    .aap-heading {
      margin-bottom: 0 !important;
      border-bottom: unset !important;

      label {
        padding-bottom: 0 !important;
      }
    }

    .btn-wrapper {
      margin-top: 0 !important;

      .btn-yellow-filled {
        margin: 0 !important;
      }
    }
  }

  .search-filter-wrapper {
    margin-bottom: 20px;
  }
}

//================= Prescription style ===========
.modal-content {
  width: 820px;
}

.modal-body {
  padding: 0px !important;
}

.orderModalInvoice {
  .modalHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 60px;

    .upperBlue {
      background: url("../images/upperBlue.svg");
      height: 150px;
      width: 63%;
      background-repeat: no-repeat;

      .docName {
        position: absolute;
        top: 40px;
        left: 60px;
        color: var(--text_dark_blue_34528A);
        font-size: 40px;
        font-weight: 600;
      }

      .domainLab {
        position: absolute;
        top: 90px;
        left: 60px;
        font-size: 21px;
        color: var(--text_dark_blue_042486);
      }

      .certificateLab {
        position: absolute;
        top: 140px;
        left: 60px;
        font-size: 15px;
        color: var(--text_dark_blue_042486);
      }
    }

    .docSymbolBox {
      .docSymImg {
        height: 130px;
        width: 130px;
        position: absolute;
        top: 45px;
        right: 60px;
      }
    }
  }

  .modalBody {
    margin: 0px 60px;
    position: relative;

    .detailsFill {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      .labelTxt .keyQuestion {
        font-size: 16px;
        color: var(--text_light_table_heading_A3AED0);
      }

      .labelTxt {
        width: 21% !important;

        .widthFixed {
          font-size: 16px;
          color: var(--text_light_table_heading_A3AED0);
        }
      }

      .form-control {
        border: none;
        border-bottom: 1px solid var(--text_dark_blue_34528A);
        border-radius: 0px !important;
        padding: 0px;
        font-size: 16px;
        color: var(--text_dark_blue_042486);
        padding-left: 10px;
        width: 100%;

        &:disabled {
          background-color: transparent;
        }

        &::placeholder {
          color: var(--text_dark_blue_042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .addressFlex {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 12px;

      .labelTxt {
        width: 12%;

        .keyQuestion {
          font-size: 16px;
          color: var(--text_light_table_heading_A3AED0);
        }
      }

      .form-control {
        border: none;
        border-bottom: 1px solid var(--text_dark_blue_34528A);
        border-radius: 0px !important;
        padding: 0px;
        padding-left: 10px;
        width: 100%;
        font-size: 16px;
        color: var(--text_dark_blue_042486);

        &:disabled {
          background-color: transparent;
        }

        &::placeholder {
          color: var(--text_dark_blue_042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .dateNdAgeFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      width: 100%;

      .ageBox {
        display: flex;
        align-items: center;
        width: 40%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--text_light_table_heading_A3AED0);
        }

        .form-control {
          border: none;
          border-bottom: 1px solid var(--text_dark_blue_34528A);
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          font-size: 16px;
          color: var(--text_dark_blue_042486);

          &:disabled {
            background-color: transparent;
          }

          &::placeholder {
            color: var(--text_dark_blue_042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .dateBox {
        display: flex;
        align-items: center;
        width: 55%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--text_light_table_heading_A3AED0);
        }

        .form-control {
          border: none;
          border-bottom: 1px solid var(--text_dark_blue_34528A);
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          font-size: 16px;
          color: var(--text_dark_blue_042486);

          &:disabled {
            background-color: transparent;
          }

          &::placeholder {
            color: var(--text_dark_blue_042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }

    .tableAndLandingBg {
      margin-top: 50px;
      position: relative;
      margin-bottom: 200px;

      .backGroundPic {
        margin-top: 78px;
        position: absolute;
        right: 0px;
        left: 0px;
        background: url("../images/bg_animal_receipt.png") no-repeat bottom
          scroll;
        background-size: contain;
        height: 380px;
        min-width: 500px;
      }

      .innerTable {
        margin-bottom: 100px;

        .modalTable {
          border: 1px solid var(--text_light_table_heading_A3AED0);
          width: 100%;
          border-top-left-radius: 20px !important;

          thead {
            border-bottom: 1px solid var(--text_light_table_heading_A3AED0);
            width: 100%;

            tr {
              th {
                font-size: 14px;
                color: var(--text_dark_blue_34528A);
                padding: 20px;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 14px;
                color: var(--text_dark_blue_34528A);
                padding: 10px 20px;
              }
            }
          }
        }
      }
    }

    .signatureContent {
      display: flex;
      justify-content: end;
      margin-bottom: 40px;

      .sigBox {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        .line {
          border-bottom: 1px solid var(--text_dark_blue_34528A);
          margin-bottom: 10px;
        }

        .signaturePad {
          border-bottom: 1px solid var(--text_dark_blue_34528A);
          height: 100px;
          width: 215px;
          margin-bottom: 10px;
          cursor: pointer;

          img {
            height: 110px;
            width: 100%;
            object-fit: contain;
          }
        }

        .belowTxt {
          font-size: 16px;
          color: var(--text_dark_blue_042486);
        }
      }
    }
  }

  .belowDetails {
    height: 123px;
    .imgWrapper {
      background: url("../images/bottomBlue.svg");
      width: 100%;
      height: 100px;

      .heightAuto {
        height: inherit;
      }

      .txt {
        border-right: 2px solid var(--text_dark_blue_34528A);
        text-align: center;
        padding: 15px;

        .innerTxt {
          font-size: 20px;
          color: var(--text_dark_blue_34528A);
          padding-right: 20px;
        }
      }

      .allCOntact {
        padding: 20px 20px 20px 30px;

        .emailAndCall {
          display: flex;
          align-items: center;

          .callNnumber,
          .emialInn {
            display: flex;
            align-items: center;
            margin-right: 10px;
            margin-bottom: 20px;

            img {
              margin-right: 5px;
            }

            .numberInn {
              font-size: 16px;
              color: var(--text_dark_blue_34528A);
            }
          }
        }

        .locationInn {
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }

          .locationTxt {
            font-size: 16px;
            color: var(--text_dark_blue_34528A);
          }
        }
      }
    }
  }
}
