// ================ Common Buttons Start ==================

.cx-btn-dark {
  background-color: var(--primary_dark_bg_21C896);
  color: var(--text_white);
  border-radius: 32px;
  padding: 8px 30px;
  border: none;
  outline: none;
  box-shadow: 0px 4px 26px 0px #00000040;
}

.cx-btn-Light {
  background-color: transparent;
  color: var(--primary_dark_bg_21C896);
  border-radius: 32px;
  padding: 8px 30px;
  border: 1px solid var(--primary_dark_bg_21C896) ;
  outline: none;
  margin-right: 15px ;
  // box-shadow: 0px 4px 26px 0px #00000040 ;
}

.cx-btn-dark-2 {
  background-color: var(--primary_dark_bg_21C896) ;
  color: var(--text_white) ;
  border-radius: 20px ;
  padding: 10px ;
  border: none;
  outline: none;
  box-shadow: 0px 4px 26px 0px #00000040;
}

.btn-width-100 {
  width: 100%;
}

.btn-width-50 {
  width: 50%;
}

.btn-width-45 {
  width: 45%;
}

.btn-height {
  height: 53px;
}

.btn-height1 {
  height: 40px !important;
}

.font-14 {
  font-size: 14px;
}

.font-17 {
  font-size: 17px;
}

.cx-btn-light {
  background-color: var(--text_white);
  color: var(--primary_dark_bg_21C896);
  border-radius: 32px;
  height: 52px;
  border: 1px solid var(--primary_dark_bg_21C896);
  //   font-size: 17px;
}

.link_common {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary_dark_bg_21C896);
  text-decoration: none;
  margin-left: 10px;
}

label {
  font-size: 13px;
  font-weight: 500;
  color: var(--text_label_gray);
}

.form-control {
  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: var(--text_placeholder);
  }
}

a {
  text-decoration: none;
}



::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  display: none;

}

::-webkit-scrollbar-thumb {
  background: var(--primary_dark_bg_21C896);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

// ================ Common Buttons End ==================

.selectBox {
  // width: 40%;

  .form-select {
    border: none;
    height: 40px;
    color: #8F9BBA;
    font-size: 14px;
    border-radius: 10px;
    // background-image: url('../images/icons/selectIcon.svg');
    background-size: 10px;

    &:focus {
      box-shadow: none;
    }
  }
}

.Top_Add_Btn { 
  border: none;
  background-color: var(--bg_White);
  border-radius: 10px;
  padding: 0 10px;
  height: 40px;
}

.Main_Page_Scroll {
  overflow-y: auto;
  height: calc(100vh - 265px);
}

.Scroll_With_Bottom_Btn {
  overflow-y: auto;
  height: calc(100vh - 385px);
}

.Form_main {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;

  .Heading {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--primary_dark_bg_21C896);
    margin-bottom: 15px;
  }

  .common-input-section {
    display: flex;
    flex-direction: column;

    label {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--primary_dark_bg_21C896);
      margin-bottom: 10px;
    }

    input {
      border: 1px solid #A3AED0;
      font-size: 14px;
      padding: 7px 10px;
      border-radius: 10px;

      &:focus-visible {
        outline: none;
      }
    }

    select {
      border: 1px solid #A3AED0;
      font-size: 14px;
      padding: 7px 10px;
      border-radius: 10px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .InventoryProduct {
      display: flex;

      img {
        margin-right: 10px;
        background-color: white;
        border-radius: 10px;
        padding: 10px;
      }
    }

    .Belt_img {
      padding: 10px;

      img {

        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #34528A26;
        height: 100px;
        width: 100px;
      }
    }
  }
}

.Button_Bottom {
  margin-top: 15px;
  display: flex;
  justify-content: end;
}
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
}
.scheule_tody{
  font-size: 13px;
  display: flex;
  justify-content: center
    // margin-left: 161px;
    // margin-top: 24px;
}